import React from 'react';
import LayoutBase from '../components/Layout';
import { Col, Row, Button, Checkbox, Form, Input } from 'antd';
import type { FormProps } from 'antd';
type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  console.log('Success:', values);
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
const App = (props: any) => {
  return (
    <LayoutBase>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Usuário"
              name="username"
              rules={[{ required: true, message: 'Informe o usuário!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Senha"
              name="password"
              rules={[{ required: true, message: 'Informe a senha!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<FieldType>
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Lembrar</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}></Col>
      </Row>

    </LayoutBase>
  );
};

export default App;

