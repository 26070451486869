import React from 'react';
import ReactDOM from 'react-dom';
import App from './presentation/pages/App'
const mount = (props: any, el: any) => {
    ReactDOM.render(
        <App />,
        el
    )
};

const devRoot = document.querySelector('#_poc')
const prop = {};
mount({ ...prop }, devRoot);



export { mount };